.modal {
    &-dialog {
        max-width: 1230px;
    }
    &-content {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        padding: 10px;
        border: none;
    }
    &-inner {
        position: relative;
        border-radius: 12px;
        border: 3px solid black;
        overflow: hidden;
    }
    .btn-round--close {
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: 2;
    }
    .btn-round {
        position: absolute;
        z-index: 2;
        bottom: 30px;
        &--next {
            left: calc(50% + 30px);
        }
        &--prev {
            right: calc(50% + 30px);
        }
    }
    @media (max-width: 1300px) {
        &-dialog {
            max-width: 900px;
        }
    }
    @media (max-width: 992px) {
        &-dialog {
            max-width: 700px;
        }
    }
    @media (max-width: 768px) {
        &-dialog {
            max-width: 500px;
        }
        .btn-round--close {
            top: 20px;
            right: 20px;
        }
        .btn-round {
            bottom: 20px;
        }
    }
    @media (max-width: 575px) {
        &-dialog {
            max-width: 343px;
            margin: auto;
        }
       
    }
}
#form-modal{
    .modal-inner {
        border: none;
    }
    @media (max-width: 768px) {
        .form-inner {
            padding: 16% 12.6%;
        }
    }
    @media (max-width: 575px) {
        .form-inner {
            padding: 24% 12.6%;
        }
    }
}
.player-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 1000;
}

.player-overlay .close-btn {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.player-overlay .close-btn svg {
    fill: #ffffff;
}

.player-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 1200px;
    width: 100%;
}

.player-wrapper::before {
    content: '';
    position: relative;
    top: 0px;
    left: 0px;
    padding-bottom: 56.25%;
    width: 100%;
    display: block;
}

#player {
    position: absolute;
    top: 0;
    left: 0;
}