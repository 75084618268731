#products {
    // margin-top: -18%;
    padding-bottom: 10%;
    position: relative;
    .ball-1 {
        width: 3.23%;
        top: 10%;
        left: 90%;
        filter: blur(3px);
    }
    .ball-2 {
        width: 4.58%;
        top: 50%;
        left: 5%;
        filter: blur(7.5px);
    }
    .ball-3 {
        width: 5.52%;
        top: 80%;
        left: 35%;
    }
}
.products {
    display: flex;
    justify-content: space-between;
}
.product {
    flex: 0 0 calc((100% - 120px) / 3);
    width: calc((100% - 120px) / 3);
    position: relative;
    img {
        max-width: 100%;
    }
    .btn-round {
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        
        transition: all .3s;
        &--next {
            right: -20px;
        }
        &--prev {
            left: -20px;
        }
    }
    
    @media (hover: hover) {
        .btn-round {
            opacity: 0;
            pointer-events: none;
        }
        &:hover {
            .btn-round {
                opacity: 1;
                pointer-events: initial;
            }
        }
    }
    .swiper {
        padding-bottom: clamp(18px, 1.04vw, 20px);
    }
    .swiper-slide {
        text-align: center;
        cursor: pointer;
    }
   &:last-child .swiper-slide img {
        transform: translateY(8%);
   }

}


.swiper {
   
    .swiper-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0!important;
    }
    .swiper-pagination-bullet {
        background-color: var(--blue);
        opacity: 1;
        width: clamp(12px, 0.78vw, 15px);
        height: clamp(12px, 0.78vw, 15px);
        &.swiper-pagination-bullet-active {
            background-color: var(--blue);
            opacity: 1;
            width: clamp(18px, 1.04vw, 20px);
            height:  clamp(18px, 1.04vw, 20px);
        }
    }
}
@media (max-width: 1400px) {
    .product {
        .btn-round {
            &--next {
                right: -30px;
            }
            &--prev {
                left: -30px;
            }
        }
    }
}
@media (max-width: 992px) {
    .products {
        flex-direction: column;
        align-items: center;
    }
    .product {
        flex: 0 0 clamp(296px, 100%, 437px);
        width: clamp(296px, 100%, 437px);
        &:last-child .swiper-slide img {
            transform: translateY(0%);
       }
    }
    #products {
        .ball-1 {
            width: 6%;
            top: 7%;
            left: 80%;
        }
        .ball-2 {
            width: 12%;
            top: 36%;
            left: -6%;
        }
        .ball-3 {
            width: 8%;
            top: 95%;
            left: 10%;
        }
    }
    
    @media (max-width: 575px) {
        .product {
            .btn-round {
                &--next {
                    right: 0px;
                }
                &--prev {
                    left: 0px;
                }
            }
            .swiper-slide {
                padding-left: 30px;
                padding-right: 30px;
            }
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
        #products {
            .ball-1 {
                width: 8.2666%;
            }
            .ball-2 {
                width: 16.533333%;
            }
            .ball-3 {
                width: 12.26666%;
            }
        }

    }
    
}