@import 'bootstrap.scss';
body {
    --blue: #8DD4DA;
    --title-fs: clamp(24px, 2.4vw, 2.4vw);
    --subtitle-fs: clamp(18px, 1.6vw, 1.6vw);
    font-family: 'Montserrat';
    letter-spacing: -0.015em;
    position: relative;
    margin: 0;
}
* {
    box-sizing: border-box;
}
// html, body {
//     overflow-x: hidden;
// }
// section {
//     overflow-x: auto;
// }
.body-inner {
    overflow: hidden;
}
button {
    cursor: pointer;
    padding: 0;
}
img {
    max-width: 100%;
}
.text-center {
    text-align: center;
}
.bold {
    font-weight: 800;
}
.loader {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--blue);
    display: flex;
    // display: none;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: linear-gradient(-45deg, #F5B5D2, var(--blue), #F5B5D2, var(--blue));
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
    transition: all .3s;
    z-index: 1000;
    &__logo {
        transform: scale(.6) rotate(0.01deg);
        animation: loader-logo 1s infinite alternate;
    }
    @keyframes loader-logo {
        0% {
            transform: scale(.6) rotate(0.01deg);
        }
        100% {
            transform: scale(1) rotate(0.01deg);
        }
    }
    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    &.hide {
        opacity: 0;
    }
}


.title {
    font-weight: 800;
    font-size: var(--title-fs);
    line-height: 114.9%;
    text-transform: uppercase;
}
.subtitle {
    font-size: var(--subtitle-fs);
    margin-top: 20px;
    margin-bottom: 50px;
}

 .mb-100 {
    margin-bottom: 2.2em;
 }

.header {
    background-color: var(--blue);
    position: fixed;
    left: 0;
    width: 100%;
    top: 0;
    z-index: 10;
    padding: 10px 0px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    // transform: translateY(-100%);
    transition: all .3s;
    .container {
        display: flex;
        // justify-content: space-between;
        align-items: center;
    }
    a {
        font-size: clamp(20px, 1.8vw, 1.8vw);
        color: white;
        text-decoration: none;
        font-weight: 800;
        margin: 0 20px 0 auto;
        display: flex;
        align-items: center;
        svg {
            width: 32px;
            height: 32px;
            display: none;
           
        }
        @media (max-width: 640px) {
                span {
                    display: none;
                }
                svg {
                    display: block;
                }
        }
        @media (max-width: 440px) {
                margin-right: 10px;
                svg {
                    width: 24px;
                    height: 24px;
                }
        }
    }
    &__logo {
        // flex: 0 0 281px;
        width: clamp(200px, 14.6vw, 14.6vw);
        img {
            max-width: 100%;
            display: block;
        }
    }
    &.show {
        transform: translateY(0);
    }
    @media (max-width: 800px) {
        &__logo {
            width: 52px;
        }
    }
    @media (max-width: 575px) {
        .container {
            padding-left: 10.6%;
            padding-right: 10.6%;
        }
        
    }
}


.balls-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 2;
    .ball {
        position: absolute;
        animation-name: floating;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }
    .layer {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    @keyframes floating {
        0% { transform: translate(0,  0px); }
        50%  { transform: translate(0, 30%); }
        100%   { transform: translate(0, -0px); }   
    }
   
}
#description {
    margin-top: -14%;
    margin-bottom: 50px;
    // background-image: url('../img/braslet_flower.png');
    background-repeat: no-repeat;
    background-size: 400px;
    background-position: right center;
    .cont {
        max-width: 900px;
        margin: auto;
    }
    p {
        font-size: 18px;
        line-height: 1.6;
        display: flex;
        align-items: center;
        &+p {
            margin-top: 40px;
        }
        &::before {
            content: '';
            display: block;
            flex: 0 0 100px;
            width: 100px;
            height: 100px;
            background-image: url('../img/balls/pink-ball.png');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 30px;
        }
        &:nth-child(2)::before {
            background-image: url('../img/balls/purple-ball.png');
        }
        &:nth-child(3)::before {
            background-image: url('../img/balls/pink-light-ball.png');
        }
        @media (max-width: 1580px) {
            &::before {
                width: 60px;
                height: 60px;
                flex: 0 0 60px;
                margin-right: 20px;
            }
            &+p {
                margin-top: 20px;
            }
        }
        @media (max-width: 1300px) {
            font-size: 16px;
        }
        @media (max-width: 500px) {
            flex-direction: column;
            text-align: center;
            &::before {
                margin-bottom: 20px;
                margin-right: 0;
            }
        }
    }
    button {
        margin-top: 50px;
    }
}
.twi {
    display: flex;
    flex-direction: row;
    align-items: center;
    &__img {
        text-align: center;
    }
    &>* {
        flex: 0 0 50%;
        width: 50%;
    }
    @media (max-width: 1300px) {
        &>* {
            flex: 0 0 50%;
            width: 50%;
        }
        &__text {
            width: 60%;
            flex: 0 0 60%;
        }
        &__img {
            width: 40%;
            flex: 0 0 40%;
        }
    }
    @media (max-width: 992px) {
        flex-direction: column;
        &>* {
            flex: 0 0 100%!important;
            width: 100%!important;
        }
    }
}
#get-plan {
    margin-bottom: 100px;
    margin-top: 100px;
}
@media (max-width: 992px) {
   body {
    --title-fs: clamp(20px, 2.4vw, 2.4vw);
   }
}
@media (max-width: 575px) {
   body {
    --title-fs: clamp(20px, 5.3vw, 5.3vw);
   }
   .mb-100 {
        margin-bottom: 1em;
    }
}
@media (max-width: 375px) {
   body {
    --title-fs: clamp(14px, 5.3vw, 5.3vw);
   }
}
@import 'main';
@import 'products';
@import 'buttons';
@import 'popular';
@import 'benefits';
@import 'marketing';
@import 'form';
@import 'footer';
@import 'modals';