.button {
    font-size: clamp(18px, 1.45vw, 1.45vw);
    text-transform: uppercase;
    font-weight: bold;
    background-color: white;
    border: none;
    padding: 0.5em 1em;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 0.64em;
    line-height: 1;
    // outline-offset: -0.357em;
    // outline-style: solid;
    // outline-width: 0.124em;
    // outline-color: black;
    font-family: inherit;
    color: black;
    position: relative;
    &::after {
        --offset: 0.257em;
        content: '';
        display: block;
        position: absolute;
        top: var(--offset);
        left: var(--offset);
        right: var(--offset);
        bottom: var(--offset);
        border-radius: 0.38em;
        border: 0.124em solid black;
    }

    @media (max-width: 992px) {
        font-size: 16px;
        // outline-width: 3px;
        // outline-offset: -11px;
        border-radius: 24px;
        padding: 16px 32px;
        &::after {
            --offset: 8px;
            border-radius: 24px;
            border: 3px solid black;
        }
    }
    @media (max-width: 410px) {
        font-size: 14px;
        padding: 16px 22px;
    }
    @media (max-width: 375px) {
        font-size: 12px;
        padding: 12px 16px;
        &::after {
            --offset: 4px;
            border: 2px solid #000;
        }
    }
}
// .button {
//     font-size: 1.75rem;
//     text-transform: uppercase;
//     font-weight: bold;
//     background-color: white;
//     border: none;
//     padding: 0.88rem 1.75rem;
//     box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
//     border-radius: 1.13rem;
//     line-height: 1;
//     outline-offset: -0.65rem;
//     outline-style: solid;
//     outline-width: 3px;
//     outline-color: black;

// }
.btn-round {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    background-color: white;
    // outline-offset: -10px;
    // outline-style: solid;
    // outline-width: 3px;
    // outline-color: black;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    position: relative;
    &::after {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    &::before {
        --offset: 6px;
        content: '';
        display: block;
        position: absolute;
        top: var(--offset);
        left: var(--offset);
        right: var(--offset);
        bottom: var(--offset);
        border-radius: 50%;
        border: 3px solid black;
    }
    &--next {
        &::after {
            background-image: url('../img/arrow-icon.svg');
        }
    }
    &--prev {
        &::after {
            background-image: url('../img/arrow-icon.svg');
            transform-origin: center;
            transform: translate(-50%, -50%) rotate(180deg);
        }
    }
    &--close {
        &::after {
            background-image: url('../img/close-icon.svg');
        }
    }

    @media (max-width: 575px) {
        width: 44px;
        height: 44px;
        &::after {
            width: 17px;
            height: 17px;
        }
    }
}