// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
// $font-family-sans-serif: 'Montserrat';

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "../../../node_modules/bootstrap/scss/variables";
// @import "../../../node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1224px,
  xxl: 1549px
);

$container-max-widths: (
  sm: 80.7%,
  md: 80.7%,
  lg: 80.7%,
  xl: 80.7%,
  xxl: 80.7%
);
// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1200px,
//   xxl: 1525px
// );

// $modal-sm: 300px;
// $modal-md: 500px;
// $modal-lg: 800px;
// $modal-xl: 1230px;


// 5. Include remainder of required parts
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";
// @import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/transitions";
