.main {
    --anim-duration: 0.8s;
    // aspect-ratio: 1920 / 1706;
    position: relative;
    &::before{
        content: '';
        display: block;
        padding-bottom: 88.85416667%;
    }
    overflow: hidden;
    img {
        display: block;
    }
    &__back {
        // position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        // height: 100vh;
    }
    .container {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
    &__logo{
        padding-top: 4%;
        width: clamp(200px, 25vw, 25vw);
        opacity: 0;
        visibility: hidden;
        // width: 25vw;
        transition: all var(--anim-duration) cubic-bezier(0.34, 1.56, 0.64, 1);
        img {
            width: 100%;
            padding-bottom: 12%;
        }
        .before-state & {
            transform: translateY(-100%);
            opacity: 0;
        }
        
    }
     .title {
        // max-width: 700px;
        max-width: clamp(240px, 36.5vw, 36.5vw);
        font-size: clamp(18px, 1.8vw, 1.8vw);
        transition: all var(--anim-duration) cubic-bezier(0.34, 1.56, 0.64, 1);
        &+p {
            
            max-width: clamp(240px, 36.5vw, 36.5vw);
            font-size: 20px;
            transition: all var(--anim-duration) cubic-bezier(0.34, 1.56, 0.64, 1);
            margin: 0;
            @media (max-width: 992px) {
                font-size: 18px;
            }
        }
    }
    .button {
        margin-top: 20px;
        transition: all var(--anim-duration) cubic-bezier(0.34, 1.56, 0.64, 1);
        
    }
    &.before-state {
        .title, p {
            transform: translateX(-50%);
            opacity: 0;
        }
        .button {
            transform: translateY(150%);
            opacity: 0;
        }
        
    }
    &__bg{
        width: 100%;
        transition: all var(--anim-duration);
        .before-state & {
            opacity: 0;
        }
    }
    &__top-left-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(688 / 1920 * 100%);
        transition: all var(--anim-duration);
        .before-state & {
            opacity: 0;
        }
    }
    &__big-wave {
        position: absolute;
        top: -2%;
        right: 0;
        width: 100%;
        transition: all var(--anim-duration);
        .before-state & {
            opacity: 0;
        }
    }
    &__bloger {
        position: absolute;
        right: -3%;
        top: -5.9%;
        width: 53%;
        transition: all var(--anim-duration) cubic-bezier(0.16, 1, 0.3, 1);
        transform-origin: right bottom;
        .before-state & {
            transform: translateX(100%) scale(.7);
            opacity: 0;
        }
    }
    &__kulon {
        position: absolute;
        left: 42%;
        top: -17%;
        bottom: 15%;
        width: 21%;
        transition: all var(--anim-duration) cubic-bezier(0.34, 1.56, 0.64, 1);
        transform-origin: center;
        .before-state & {
            transform: translateY(-50%) rotateZ(30deg);
            opacity: 0;
        }
    }
    &__epoxy {
        position: absolute;
        top: 24%;
        left: 46%;
        width: 14%;
        transition: all var(--anim-duration) cubic-bezier(0.34, 1.56, 0.64, 1);
        transform-origin: center;
        .before-state & {
            transform: scale(.3) rotate(0.01deg);
            opacity: 0;
        }
        
    }
    &__braslet {
        position: absolute;
        left: -6%;
        top: 16%;
        width: 26%;
        transition: all var(--anim-duration) cubic-bezier(0.34, 1.56, 0.64, 1);
        transform-origin: center;
        .before-state & {
            transform: translateX(-50%) rotateZ(30deg);
            opacity: 0;
        }
    }
    &__products {
        position: absolute;
        top: 32%;
        left: 26%;
        width: 20%;
        transition: all var(--anim-duration) cubic-bezier(0.34, 1.56, 0.64, 1);
        transform-origin: left bottom;
        .before-state & {
            transform: scale(.4);
            opacity: 0;
        }
    }
    &__wave-overlay {
        position: absolute;
        right: 0;
        bottom: 21%;
        width: 85%;
        transition: all var(--anim-duration);
        .before-state & {
            opacity: 0;
        }
    }
    &__balls {
        .ball-1 {
            width: 3.18%;
            left: 6.25%;
            top: 45.3%;
        }
        .ball-2 {
            width: 6.7%;
            left: -3.7%;
            top: 51.53%;
            filter: blur(10px);
        }
        .ball-3 {
            width: 3.15%;
            left: 92.97%;
            top: 58.75%;
        }
        .ball-4 {
            width: 4.9%;
            left: 63.44%;
            top: 68.86%;
        }
        transition: all var(--anim-duration);
        .before-state & {
            opacity: 0;
        }
    }
    @media (max-width: 1200px) {
        &__products {
            top: 44%;
            left: 12%;
        }
    }
    @media (max-width: 992px) {
        // aspect-ratio: 1920 / 2100;
        &::before {
            padding-bottom: 109.375%;
        }
        .title {
            max-width: clamp(340px, 36.5vw, 36.5vw);
        }
        &__big-wave {
            width: 109%;
            top: 10%;
            right: -8%;
            max-width: unset;
        }
        &__bloger {
            top: 13.1%;
            right: -3%;
        }
        &__wave-overlay {
            bottom: 19%;
            right: -2%;
        }
        &__epoxy {
            top: 35%;
        }
        &__products {
            top: 44%;
            width: 30%;
        }
        &__kulon {
            left: 46%;
            top: -10%;
        }
    }
    @media (max-width: 575px) {
        // aspect-ratio: 1920 / 5200;
        &::before {
            padding-bottom: 270.83333%;
        }
        .container {
            max-width: 100%;
            padding-left: 10.6%;
            padding-right: 10.6%;
        }
        &__top-left-bg {
            width: 91%;
            top: -2%;
            left: -16%;
        }
        .title {
            max-width: 100%;
        }
        &__big-wave {
            width: 178%;
            top: 46%;
            right: -15%;
            
        }
        &__bloger {
            width: 95%;
            top: 44.1%;
            right: -20%;
            z-index: 1;
        }
        &__wave-overlay {
            width: 148%;
            max-width: unset;
            bottom: 7%;
            right: -11%;
            z-index: 1;
        }
        &__epoxy {
            top: 56%;
            width: 37%;
            left: 5%;
        }
        &__products {
            width: 50%;
            top: 40%;
            left: 25%;
        }
        &__kulon {
            width: 40%;
            top: -10%;
            left: 61%;
        }
        &__braslet {
            width: 40%;
            top: 16%;
            left: -16%;
        }
        &__logo {
            width: 63.3%;
            padding-top: 17.78%;
            img {
                padding-bottom: 20%;
            }
        }
        &__balls {
            .ball-1 {
                width: 6.13%;
                left: 84%;
                top: 38%;
            }
            .ball-2 {
                width: 13.333%;
                left: -5.7%;
                top: 51.53%;
            }
            .ball-3 {
                display: none;
            }
            .ball-4 {
                width: 9.6%;
                left: 56%;
                top: 85%;
            }
        }
    }
}