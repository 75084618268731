#popular {
    // overflow: hidden;
    position: relative;
    img {
        display: block;
        max-width: unset;
        // width: 100%;
    }
    .ball-1 {
        width: 2.81%;
        left: 85%;
        top: 10%;
    }
    .ball-2 {
        width: 3.16%;
        left: -.2%;
        top: 30%;
    }
    .ball-3 {
        width: 2.41%;
        left: 80%;
        top: 140%;
    }
}
.popular-scene {
    position: relative;
    // img {
    //     width: 100%;
    // }
    // &::after {
    //     content: '';
    //     display: block;
    //     padding-bottom: 5%;
    // }
    &__bg {
        position: absolute;
        z-index: -1;
        width: 100%;
        top: 0;
        top: -23.4%;
        img {
            width: 100%;
        }
        // height: 1641px;
        // background-image: url('../img/popular/wave.svg');
        // background-position: center;
        // background-size: cover;
        // background-repeat: no-repeat;
    }
    // &__bg {
    //     position: absolute;
    //     z-index: -1;
    //     width: 100%;
    //     top: 0;
    //     top: -23.4%;
    // }
    
}
.bloggers {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 38%;
    // &::after {
    //     content: '';
    //     position: absolute;
    //     width: 100%;
    //     display: block;
    //     margin-bottom: 120%;
    // }
    .wave-overlay {
        width: 122.6%;
        position: absolute;
        bottom: -21%;
        left: -9.7%;
        pointer-events: none;
        img {
            max-width: unset;
            width: 100%;
        }
    }
    .title {
        position: absolute;
        top: 6%;
        left: 50%;
        transform: translateX(-50%);
        max-width: calc(600 / 1920 * 100%);
    }
    // .shadow {
    //     filter: drop-shadow(30px 0px 20px #00000045);
    // }
    &__text {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 135%;
        width: 47.1875vw;
        margin: auto;
        text-align: center;
        // font-size: 1.875vw;
        font-size: clamp(18px, 1.875vw, 1.875vw);
        text-transform: uppercase;
    }
}
.blogger {
    &-left {
        position: relative;
        width: 33%;
        min-width: 33%;
        &__photo {
            width: 100%;
            transition: all .3s;
            transform-origin: center bottom;
        }
        &__shadow {
            position: absolute;
            z-index: -1;
            width: 150%!important;
            top: 8%;
            left: -23%;
            max-width: unset;
        }
        &__name {
            position: absolute;
            width: 57.54%;
            left: 32%;
            top: 62%;
            transition: all .3s;
            transform-origin: center;
            pointer-events: none;
        }
        &:hover {
            .blogger-left__photo {
                transform: scale(1.05);
            }
            .blogger-left__name {
                transform: rotate(-3.6deg);
            }
            &:hover+.blogger-content .blogger-pack img {
                transform: scale(1.05);
            }
        }
    }
    &-right {
        position: relative;
        width: 38%;
        min-width: 38%;
        &__photo {
            width: 100%;
            transition: all .3s;
            transform-origin: center bottom;
        }
        &__shadow {
            position: absolute;
            z-index: -1;
            width: 165%!important;
            top: 5%;
            left: -40%;
            max-width: unset;
        }
        &__name {
            position: absolute;
            width: 51.02%;
            top: 62%;
            left: 19%;
            transition: all .3s;
            transform-origin: center;
            pointer-events: none;
        }
        &:hover {
            .blogger-right__photo {
                transform: scale(1.05);
            }
            .blogger-right__name {
                transform: rotate(12.48deg);
            }
            &:hover+.blogger-content .blogger-pack img {
                transform: scale(1.05);
            }
        }
    }
    &-content {
        position: absolute;
        width: 50%;
        display: flex;
        top: 100%;
        align-items: flex-end;
        transform: translateY(-53%);
        pointer-events: none;
        z-index: 3;
        &--left {
            left: 0;
        }
        &--right {
            right: 0;
        }
    }
    &-pack {
        min-width: 18vw;
        img {
            width: 100%;
            transition: all .3s;
            transform-origin: center bottom;
        }
    }
}
.subscribers {
    display: flex;
    flex-direction: column;
    align-items: center;
    &+& {
        margin-left: 1.3%;
    }
    &__logo {
        margin-bottom: 6%;
        min-width: 8.59vw;
        max-width: 8.59vw;
        img {
            // max-width: 100%;
            width: 100%;
        }
    }
    &__counter {
        font-weight: bold;
        font-size: 1.5625vw;
        // position: relative;
        line-height: 1;
        &::before {
            content: '>';
            margin-right: 0.25em;

        }
    }    
    &__text {
        font-size: 1.3541vw;
    }
    &--tr-b {
        transform: translateY(25%);
    }
    &--tr-t {
        transform: translateY(-25%);
    }
}
@media (max-width: 768px) {
    .popular-scene {
        &__bg {
            top: 27.6%;
        }
        .container {
            max-width: 100%;
        }
        .wave-overlay {
            width: 101vw;
            top: 58%;
            left: 50%;
            transform: translateX(-50%);
        }
        .title {
            transform: translate(-50%, -30%);
            max-width: unset;
            top: 0;
        }
    }
    .bloggers {
        margin-bottom: 100%;
        margin-top: clamp(20px, 20%, 100px);
    }
    .bloggers__text {
        // width: 78.93vw;
        width: 60vw;
        top: 158%;
    }
    .blogger {
        &-left {
            min-width: 64.53vw;
            max-width: 64.53vw;
            transform: translate(-5%, 0);
            &__shadow {
                display: none!important;
            }
            &__name {
                width: 52.54%;
                top: 58%;
                left: 12%;
                // opacity: 0;
            }
            
        }
        &-right {
            min-width: 86.4vw;
            max-width: 86.4vw;
            transform: translate(-60.5%, 1.3%);
            &__shadow {
                display: none!important;
            }
            &__name {
                // opacity: 0;
                width: 42%;
                top: 57%;
                left: 49%;
            }
        }
        &-content {
            flex-direction: column;
            align-items: center;
            transform: translateY(0%);
            top: 85%;
            &--left {
                flex-direction: column-reverse;
            }
        }
        &-pack {
            min-width: 36.26vw;
            max-width: 36.26vw;
        }
    }
    .subscribers {
        transform: unset;
        &+& {
            margin-left: 0;
        }
        &--tr-b {
            margin-top: 10%;
        }
        &__logo {
            min-width: 21.6vw;
            max-width: 21.6vw;
        }
        &__counter {
            font-size: clamp(14px, 3.7vw, 3.7vw);
        }
        &__text {
            font-size: clamp(11.25px, 3vw, 3vw);
        }
    }
    #popular {
        .ball-1 {
           display: none;
        }
        .ball-2 {
            display: none;
        }
        .ball-3 {
            width: 6%;
            top: 90%;
            left: 89%;
        }
    }
}

@media (max-width: 575px) {
    .bloggers {
        margin-bottom: 126%;
    }
    .bloggers__text {
        width: 78.93vw;
    }
    .bloggers__text {
        font-size: clamp(16px, 4.26vw, 4.26vw );
        width: 78.93vw;
        top: 158%;
    }
}