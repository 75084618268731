.form {
    &-row {
        display: flex;
        &+& {
            margin-top: 30px;
        }
        .button {
            margin: 0 auto;
        }
    }
    &-wrapper {
        max-width: 1230px;
        margin: auto;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        border: 0.58vw solid white;
        // border: 10px solid white;
        position: relative;
        .btn-round--close {
            position: absolute;
            top: 30px;
            right: 30px;
            z-index: 2;
        }
        .brelok {
            position: absolute;
            width: 47%;
            top: 50%;
            left: 79%;
        }
        .bisser {
            position: absolute;
            width: 65%;
            left: 2%;
            top: 100%;
            transform: translateY(-80%);
            z-index: -1;
        }
       
    }
    &-inner {
        position: relative;
        border-radius: 12px;
        // border: 3px solid black;
        border: clamp(2px, 0.15625vw, 3px) solid black;
        overflow: hidden;
        background-color: var(--blue);
        padding: 4.065% 12.60%;
    }
    &-response {
        background-color: var(--blue);
        padding: 4% 14%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        display: none;
        opacity: 0;
        transition: all .3s;
        &.show {
            display: flex;
            opacity: 1;
        }
        &__text {
            margin-bottom: 0.4em;
            font-size: clamp(24px, 2.0833vw, 40px);
        }
        &__icon {
            width: clamp(80px, 7.1875vw, 138px);
        }
    }
    
    
}
.input-wrapper {
    // min-height: 96px;
    // padding: 10px;
    padding: 1.11%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    background-color: white;
    width: 100%;
    flex: 1 1 auto;
    &.w-50 {
        width: calc((100% - 30px) / 2);
        &+.w-50 {
            margin-left: 30px;
        }
        // flex: 1 0 100%;
    }
}
input, select {
    width: 100%;
    height: 100%;
    // border: 3px solid black;
    border: clamp(2px, 0.0833em, 3px) solid black;
    border-radius: 12px;
    outline: none;
    // font-size: 36px;
    font-size: clamp(18px, 1.875vw, 1.875vw);
    padding: 0.5em 0.4166em;
    line-height: 1;
    font-family: 'Montserrat';
    background-color: white;
}
@media (max-width: 992px) {
    .form {
        &-wrapper {
            border-width: 10px;
        }
        &-inner {
            border-width: 3px;
        }
    }
    .input-wrapper {
        padding: 10px;
    }
    input, select {
        border-width: 3px;
    }
}
@media (max-width: 768px) {
    .form {
        &-row {
            flex-direction: column;
            &+& {
                margin-top: 15px;
            }
            &:last-child {
                margin-top: 30px;
            }
        }
        &-wrapper {
            .bisser {
                transform: translateY(-72%);
            }
        }
    }
    .input-wrapper {
        padding: 9px;
        border-radius: 18px;
       &.w-50 {
        width: 100%;
        &+& {
            margin-left: 0px;
            margin-top: 15px;
        }
       }
    }
    input {
        font-size: 16px;
        padding: 0.7em 0.4166em;
    }
}
@media (max-width: 575px) {
    .form {
        &-inner {
            padding: 35px 15px;
        }
        &-wrapper {
            .bisser {
                transform: translateY(-76%) matrix(-.81, .59, .59, .81, 0, 0) scaleX(-1);
                width: 307px;
            }
            .brelok {
                width: 80%;
                top: 100%;
                left: 100%;
                transform: translate(-52%, -27%);
                max-width: unset;
                pointer-events: none;
            }
        }
        &-response {
            padding: 20px;
        }
    }
}
@media (max-width: 400px) {
    .form {
        &-wrapper {
            .brelok {
                width: 100%;
            }
        }
    }
}