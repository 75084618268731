.marketing {
    position: relative;
    &__text {
        // font-size: 36px;
        font-size: clamp(18px, 1.875vw, 1.875vw);
        text-transform: uppercase;
        margin-left: 41%;
        // margin-top: 60px;
        margin-top: 4%;
        p {
            margin: 0;
            &+p {
                margin-top: 5%;
            }
        }
    }
    &__scene {
        position: relative;
       
    }
    &__bg {
        img {
            width: 100%;
            max-width: unset;
        }
    }
    &__phone {
        position: absolute;
        width: 58%;
        left: 7%;
        top: -16%;
        animation-name: floatingPhone;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }
    .ball-1 {
        width: 2.45%;
        top: 12%;
        left: 35%;
    }
    .ball-2 {
        width: 7.08%;
        top: 33%;
        left: 85%;
        filter: blur(10px);
    }
    .ball-3 {
        width: 2.97%;
        top: 80%;
        left: 55%;
    }
    @keyframes floatingPhone {
        0% { transform: translate(0,  0px); }
        50%  { transform: translate(0, 3%); }
        100%   { transform: translate(0, -0px); }   
    }
    @media (max-width: 575px) {
        .container {
            padding-left: 10.6%;
            padding-right: 10.6%;
        }
        &__text {
            font-size: max(16px, 4.26vw);
            margin-left: 0;
        }
        &__phone {
            width: 122.5%;
            top: 2%;
            left: -10%;
            max-width: unset;
        }
        .ball-1 {
            width: 5%;
            top: 3%;
            left: 6%;
        }
        .ball-2 {
            width: 22%;
            top: 36%;
        }
        .ball-3 {
            width: 5%;
            top: 87%;
            left: 85%;
        }
    }
}