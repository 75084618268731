.footer {
    margin-top: -7.3%;
    position: relative;
    .ball-1 {
        position: absolute;
        width: 10%;
        left: 10%;
        top: 14%;
        filter: blur(6px);
    }
    &__phone {
        margin-top: 70px;
        margin-bottom: 50px;
        font-size: clamp(18px, 1.875vw, 1.875vw);
        margin-top: 1.9444em;
        margin-bottom: 1.3888em;
        a {
            text-transform: uppercase;
            font-weight: 700;
            // font-size: 36px;
            text-decoration: none;
            color: inherit;
            line-height: 1;
        }
    }
    &__text {
        margin-bottom: 1.9565em;
    }
}
.socials {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
}
.social-link {
    display: block;
    img {
        display: block;
        
    }
}
.copyright {
    background-color: var(--blue);
    color: black;
    padding: 18px 0px;
    position: relative;
    &__before {
        position: absolute;
        bottom: 100%;
        left: 0;
        display: block;
        width: 100%;
        z-index: -1;
    }
}
@media (max-width: 1200px) {
   
    .social-link {
        &--likee {
            width: 174px;
        }
        &--vk {
            width: 58px;
        }
        &--youtube {
            width: 168px;
        }
    }
}
@media (max-width: 992px) {
    .social-link {
        &--likee {
            width: 138px;
        }
        &--vk {
            width: 46px;
        }
        &--youtube {
            width: 134px;
        }
    }
}
@media (max-width: 575px) {
    .footer {
        .container {
            padding-left: 10.6%;
            padding-right: 10.6%;
        }
        &__phone {
            margin-top: 4.25em;
            font-size: clamp(20px, 4.26vw, 4.26vw );
        }
        &__text {
            margin-bottom: 1.5em;
        }
        .ball-1 {
            width: 23%;
            left: -5%;
            top: 60%;
        }
    }
    .socials {
        margin-bottom: 20px;
    }
    .social-link {
        margin: 0 10px;
        &--likee {
            width: 122px;
        }
        &--vk {
            width: 41px;
        }
        &--youtube {
            width: 118px;
        }
    }
    .copyright {
        padding: 8px 0;
    }
}
@media (max-width: 400px) {
    .socials {
        width: 100vw;
        margin-left: 50%;
        transform: translateX(-50%);
    }
}