.benefits {
    position: relative;
    .ball-1 {
        width: 6.82%;
        top: -5%;
        left: 25%;
        filter: blur(6px);
    }
    .ball-2 {
        width: 4.9%;
        top: 85%;
        left: 80%;
        
    }
    img {
        display: block;
    }
    .top-bg {
        width: 100%;
        &__wrapper {
            width: 100%;
            position: relative;
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 99%;
                left: 0;
                width: 100%;
                height: 600px;
                background-color: #8dd4da;
            }
        }
    }
    .bisser-top-left {
        position: absolute;
        width: 41.67%;
        top: 0;
        transform: translateY(-42%);
        left: 0%;
        z-index: -1;
    }
    .bisser-top-right {
        position: absolute;
        width: 41.67%;
        top: 1%;
        right: 0%;
        z-index: -1;
    }
    .bisser-bottom-left {
        position: absolute;
        width: 41.67%;
        bottom: -9%;
        left: 0%;
        z-index: -1;
    }
    .bisser-bottom-right {
        position: absolute;
        width: 41.67%;
        bottom: 4%;
        right: 0%;
        z-index: -1;
    }
    .bisser-top-right {
        position: absolute;

    }
    .video-bg {
        position: relative;
        display: flex;
        margin-top: 4%;
        &__img {
            width: 100%;
            max-width: unset;
        }
    }
    .video-thumb {
        position: absolute;
        width: 79%;
        top: 21.3%;
        left: 10%;
        filter: drop-shadow(5px 0px 20px rgba(17, 20, 37, 0.25));
        img {
            width: 100%;
            // max-width: unset;
        }
    }
    .play-btn {
        width: 14%;
        position: absolute;
        top: 39%;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        &::after, &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            animation: rotate 2s infinite linear;
            transform-origin: center;
            box-sizing: border-box;
            background-color: white;
            border-radius: 50%;
            
        }
        &::before {
            animation-delay: 400ms!important;
        }
        svg {
            height: 100%;
            width: 100%;
            color: white;
            display: block;
            filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 1));
            path {
                transform: translateX(6%);
            }
        }
        @keyframes rotate {
            from {
                opacity: 1;
                transform: scale(0);
            }
            to {
                opacity: 0;
                transform: scale(1);
            }
        }
    }
    .icons {
        display: flex;
        flex-wrap: wrap;
        // align-items: flex-end;
        &-wrapper {
            // background-color: var(--blue);
            position: absolute;
            left: 0;
            width: 100%;
            top: 7%;
            z-index: 1;
            .title {
                margin-bottom: 1.66em;
            }
        }
    }
    .icon {
        flex: 1 1 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__img {
            // height: 170px;
            display: flex;
            align-items: flex-end;
            margin-bottom: 20px;
            justify-content: center;
            filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
            transform-origin: center;
            transition: all .3s;
            // aspect-ratio: 165 / 175;
            // width: 100%;
            // max-width: 165px;
            // padding: 10%;
            // max-width: 8.6vw;
            img {
                width: 8.6vw;
                max-width: 100%;
            }
        }
        &__text {
            text-align: center;
            font-size: 1.04vw;
            text-transform: uppercase;
        }
        &:hover {
            .icon__img {
                transform: scale(1.07);
            }
        }
    }
    @media (max-width: 1200px) {
        
        .video-bg {
            z-index: 1;
            margin-top: -4%;
            clip-path: polygon(0% 0%, 20% 5%, 100% 5%, 100% 100%, 0% 100%);
        }
        .icons {
            justify-content: center;
            &-wrapper {
                position: relative;
                // background-color: #8dd4da;
                margin-top: -10%;
                // clip-path: polygon(0% 17%, 30% 0%, 75% 0%, 100% 26%, 100% 100%, 0% 100%);
                
            }
        }
        .icon {
            flex: 0 0 calc((100% - 0px) / 4);
            margin-bottom: 10px;
            &__text {
                font-size: 14px;
            }
            &__img {
                img {
                    min-width: 92px;
                }
            }
        }
    }
    @media (max-width: 768px) {
        .icons {
            &-wrapper {
                margin-top: -7%;
                .title {
                    margin-bottom: 0.66em;
                }
            }
        }
        .icon {
            &__text {
                font-size: 12px;
            }
        }
        .ball-1 {
            width: 17%;
            top: -8%;
            left: -9%;
        }
        .ball-2 {
            width: 8%;
            top: 94%;
            left: 80%;
            
        }
    }
    @media (max-width: 575px) {
        .ball-1 {
            width: 22%;
        }
        .ball-2 {
            width: 11%;
            
        }
    }
    @media (max-width: 485px) {
        .top-bg__wrapper::after {
            height: 700px;
        }
        .icon {
            flex: 0 0 50%;
            &__text {
                font-size: 14px;
            }
        }
        .video-bg {
            display: block;
            &__img {
                width: 122%;
            }
            .video-thumb {
                width: 100%;
                left: 0;
            }
        }
    }
    @media (max-width: 350px) {
        .icon {
            &__text {
                font-size: 12px;
            }
        }
    }
}
// .benefits {
//     // .video-bg {
//     //     background-image: url('../img/benefits/video-bg.svg');
//     //     bac
//     // }
//     position: relative;

//     img {
//         display: block;
//     }
//     position: relative;
//     .video-bg {
//         position: relative;
//         display: flex;
//         height: 1410px;
//         // margin-top: -7%;
//         &__img {
//             width: 100%;
//             height: 100%;
//             object-fit: cover;
//             max-width: unset;
//         }
//         .container {
//             position: absolute;
//             left: 50%;
//             transform: translate(-50%, 0);
//             bottom: 300px;
            
//         }
//     }
//     .top-bg {
//         width: 100%;
//         height: 369px;
//         img {
//             width: 100%;
//             height: 100%;
//             object-fit: cover;
//         }
//     }
//     .video-thumb {
//         // position: absolute;
//         // width: 79%;
//         // top: 21.8%;
//         // left: 10%;
//         filter: drop-shadow(5px 0px 20px rgba(17, 20, 37, 0.25));
//         img {
//             width: 100%;
//             // max-width: unset;
//         }
//     }
//     .play-btn {
//         width: 10%;
//         position: absolute;
//         top: 42%;
//         left: 50%;
//         transform: translateX(-50%);
//         cursor: pointer;
//         svg {
//             height: 100%;
//             width: 100%;
//             color: white;
//         }
//     }
//     .icons {
//         display: flex;
//         // align-items: flex-end;
//         &-wrapper {
//             // background-color: var(--blue);
//             position: absolute;
//             left: 0;
//             width: 100%;
//             top: 7%;
//             z-index: 1;
//             .title {
//                 margin-bottom: 80px;
//             }
//         }
//     }
//     .icon {
//         flex: 1 1 0px;
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         &__img {
//             height: 170px;
//             display: flex;
//             align-items: flex-end;
//             margin-bottom: 20px;
//             justify-content: center;
//             filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
//             img {
//                 max-width: 100%;
//             }
//         }
//         &__text {
//             text-align: center;
//             font-size: 20px;
//             text-transform: uppercase;
//         }
//     }
    
// }

// @media (max-width: 1548px) {
//     .benefits {
//         .icons {

//         }
//         .icon {
//             &__text {
//                 font-size: 16px;
//             }
//             &__img {
//                 height: 140px;
//                 img {
//                     max-width: 80%;
//                 }
//             }
//         }
//     }
// }